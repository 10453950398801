<template>
    <layout>
        <page-header :title="title" :items="items" />

        <questionnaires-management
            :questionnaires="questionnaires"
            is-questionnaire-page
        />
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import QuestionnairesManagement from '@components/questionnaires/QuestionnairesManagement';

export default {
    page: {
        title: 'Questionnaires',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        QuestionnairesManagement
    },

    data() {
        return {
            title: 'Questionnaires',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Questionnaires',
                    active: true
                }
            ],
            questionnaires: []
        };
    },

    created() {
        this.fetchQuestionnaires();
    },

    methods: {
        ...mapActions({
            getQuestionnaires: 'questionnaires/index'
        }),

        async fetchQuestionnaires() {
            try {
                this.questionnaires = await this.getQuestionnaires();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    }
};
</script>
